import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { Keyboard } from '@capacitor/keyboard';
import Index from './components/Pages/Index';
import Properties from './components/Pages/Properties';
import LegalServices from './components/Pages/LegalServices';
import About from './components/Pages/About';
import Contact from './components/Pages/Contact';
import Profile from './components/Pages/Profile';
import Search from './components/Search';
import PopIndex from './components/Pages/PopularProperties';
import Index1 from './components/Pages/index1';
import PropertyList from './components/Pages/PropertyList';
import ResetPassword from './components/Pages/ResetPassword';
import ViewAllProperties from './components/Pages/ViewAllProperties';
import SearchProperties from './components/Pages/SearchProperties';
import 'bootstrap/dist/css/bootstrap.min.css';
import Signup from './components/Pages/Signup';
import AddProperty from './components/Pages/AddProperties';
import Login from './components/Pages/Login';
import Slider from './components/Pages/Slider';
import PropertyDetails from './components/Pages/PropertyDetails';
import MyPropertyContent from './components/MyPropertyContent';
import EditProperties from './components/EditProperties';
import ShortlistContent from './components/ShortlistContent';
import Header from './components/Header';
import InterestedYourProperties from './components/InterestedYourProperties';
import YourPayments from './components/YourPayments';
import OwnersYouContacted from './components/OwnersYouContacted';
import ResultsPage from './components/ResultsPage';
import Properties1 from './components/Pages/Properties1';
import useAutoLogout from './components/useAutoLogout';
import Logout from './components/Logout';
import Rewards from './components/Rewards';
import TicketChat from './components/TicketChat';
import SelectPackage from './components/SelectPackage';
import LegalServicesPage from './components/LegalServicesPage';
import FastReliablePropertyServices from './components/FastReliablePropertyServices';
import ForgotPassword from './components/Pages/ForgotPassword';
import UserLogin from './components/Pages/UserLogin';
import SelectedItemsSummary from './components/SelectedItemsSummary';
import NotFound from './components/Pages/NotFound';
import LegalService from './components/Pages/LegalService';
import Invoice from './components/Pages/Invoice';
import SearchBox from './components/Pages/SearchBox';
import Terms from './components/Pages/Terms';
import Privacy from './components/Pages/Privacy';
import Features from './components/Features';
import PostPropSignup from './components/Pages/PostPropSignup';
import Referral from './components/Pages/Referral';
import CountryStateCitySelector from './components/Pages/checkreact';
import Refund from './components/Refund';
import InvoiceGenerator from './components/InvoiceGenerator';
import MobileHeader from './components/MobileHeader';
import MobileFooter from './components/MobileFooter';
import NoInternetConnection from './components/NoInternetConnection';
import MobileLoginPage from './components/MobileLoginPage';
import MobileSignup from './components/MobileSignup';
import SelectedSummeryMobile from './components/SelectedSummeryMobile';
import CompanyCopyright from './components/CompanyCopyright';
import Example from './components/Pages/Maps';

function App() {
    useAutoLogout();
    const [properties, setProperties] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
    const [isOnline, setIsOnline] = useState(true);
    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 600);
        };
    
        const handleOnlineStatus = async () => {
            if (Capacitor.isPluginAvailable("Network")) {
                Network.addListener("networkStatusChange", (status) => {
                    // console.log("Network status changed:", status);
                    setIsOnline(status.connected);
                });
    
                try {
                    const status = await Network.getStatus();
                    // console.log("Initial network status:", status.connected);
                    setIsOnline(status.connected);
                } catch (error) {
                    console.error("Error checking network status:", error);
                }
            }
        };

        const handleKeyboardEvents = () => {
            if (Capacitor.isNativePlatform()) {
                Keyboard.addListener('keyboardWillShow', info => {
                    // console.log('keyboard will show with height:', info.keyboardHeight);
                    setIsKeyboardVisible(true);
                });
    
                Keyboard.addListener('keyboardDidShow', info => {
                    // console.log('keyboard did show with height:', info.keyboardHeight);
                });
    
                Keyboard.addListener('keyboardWillHide', () => {
                    // console.log('keyboard will hide');
                    setIsKeyboardVisible(false);
                });
    
                Keyboard.addListener('keyboardDidHide', () => {
                    // console.log('keyboard did hide');
                });
            }
        };
    
        handleOnlineStatus();
        handleKeyboardEvents();
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
            if (Capacitor.isNativePlatform()) {
                Keyboard.removeAllListeners();  // Clean up listeners on unmount
            }
        };
    }, []);
    
    return (
        <div>
             <BrowserRouter>
                <AppContent 
                    isMobile={isMobile} 
                    isOnline={isOnline} 
                    properties={properties} 
                    setProperties={setProperties} 
                    isKeyboardVisible={isKeyboardVisible}
                />
            </BrowserRouter>
        </div>
    );
}

function AppContent({ isMobile, isOnline, properties, setProperties, isKeyboardVisible  }) {
    const location = useLocation();
    const shouldHideHeaderFooter = location.pathname === "/mobilelogin" || location.pathname === "/mobilesignup";

    return (
        <>
            {!shouldHideHeaderFooter && isMobile && <MobileHeader />}
            {!isOnline ? (
                <NoInternetConnection />
            ) : (
                <Routes>
                    <Route path='/' element={<Index1 />} />
                    <Route path="/property-details/:id" element={<PropertyDetails />} />
                    <Route path='/properties' element={<Properties1 />} />
                    <Route path='/legalservices' element={<LegalServices />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/search' element={<Search setProperties={setProperties} />} />
                    <Route path='/popularprop' element={<PopIndex />} />
                    <Route path='/resetpassword' element={<ResetPassword />} />
                    <Route path='/signup' element={<Signup />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/userlogin' element={<PostPropSignup />} />
                    <Route path='/forgotpassword' element={<ForgotPassword />} />
                    <Route path='/addproperties' element={<AddProperty />} />
                    <Route path='/slider' element={<Slider />} />
                    <Route path='/itemsummary' element={<SelectedSummeryMobile />} />
                    <Route path='/summary' element={<SelectedItemsSummary />} />
                    {/* <Route path='/itemsummary' element={<SelectedSummeryMobile />} /> */}
                    <Route path="/resultsaywqpbetyaqbdlsujodbjxbiwtbafewytohmcvbsdwrruhyovbsanuranjanczswswrwexzcxdbdfshgtouor67evsdajsnxczs3swgdhjdvxsfs" element={<ResultsPage />} />
                    <Route path="/legalservicepage/:id" element={<LegalService />} />
                    <Route path="/check" element={<FastReliablePropertyServices />} />
                    <Route path='/profile' element={<Profile />} />
                    <Route path='/profile/shortlisted-properties' element={<ShortlistContent />} />
                    <Route path='/profile/owner-you-contact' element={<OwnersYouContacted />} />
                    <Route path='/profile/your-payments' element={<YourPayments />} />
                    <Route path='/profile/yourproperties' element={<MyPropertyContent />} />
                    <Route path='/profile/interested-yourproperties' element={<InterestedYourProperties />} />
                    <Route path='/profile/rewards' element={<Rewards />} />
                    <Route path='/profile/tickets' element={<TicketChat />} />
                    <Route path='/selectpackage' element={<SelectPackage />} />
                    <Route path='/allproperties' element={<ViewAllProperties />} />
                    <Route path="/edit-property/:id" element={<EditProperties />} />
                    <Route path='/pages/searchPropertyList' element={<PropertyList />} />
                    <Route path='/searchproperties' element={<SearchProperties properties={properties} />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path='/legalservice' element={<LegalService />} />
                    <Route path='/invoice' element={<Invoice />} />
                    <Route path='/searchbox' element={<SearchBox />} />
                    <Route path='/terms-condition' element={<Terms />} />
                    <Route path='/privacy' element={<Privacy />} />
                    <Route path='/feature' element={<Features />} />
                    <Route path='/dropdowncity' element={<CountryStateCitySelector />} />
                    <Route path='/profile/referral' element={<Referral />} />
                    <Route path="/profile/refunds" element={<Refund />} />
                    <Route path="/nointernet" element={<NoInternetConnection />} />
                    <Route path="/invoice/:payment_id" element={<InvoiceGenerator />} />
                    <Route path="/mobilelogin" element={<MobileLoginPage />} />
                    <Route path="/mobilesignup" element={<MobileSignup />} />
                    <Route path="/invoicegenerate/:payment_id" element={<InvoiceGenerator />} />
                    <Route path="/invoicegenerat" element={<Invoice />} />
                    <Route path="/maps" element={<Example />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            )}
            {!shouldHideHeaderFooter && isMobile && !isKeyboardVisible &&<MobileFooter />}
            {isMobile  &&<CompanyCopyright />}
        </>
    );
}

export default App;
