import React, { useState } from "react";
import ReactDatamaps from "react-india-states-map";
import { useNavigate } from "react-router-dom";

const STATES = {
  Maharashtra: {
    value: 50,
    route: "/maharashtra", 
    content: {
      txt: "Maharashtra: Known for its rich culture and history."
    }
  },
  Gujarat: {
    value: 40,
    route: "/gujarat",
    content: {
      txt: "Gujarat: Famous for its diverse culture and traditions."
    }
  },
  Karnataka: {
    value: 30,
    route: "/karnataka", 
    content: {
      txt: "Karnataka: Home to many historical sites and vibrant cities."
    }
  },

};

const Example = () => {
  const navigate = useNavigate(); 
  const [activeState, setActiveState] = useState({
    data: STATES.Maharashtra,
    name: "India"
  });

  const [stateLists, setStateLists] = useState(STATES);

  const stateOnClick = (data) => {
    setActiveState({ data, name: data.name });
    const stateRoute = data.route; 
    if (stateRoute) {
      navigate(stateRoute); 
    }
  };

  return (
    <ReactDatamaps
      regionData={stateLists}
      mapLayout={{
        hoverTitle: "Count",
        noDataColor: "#D36418",
        borderColor: "#ffffff"
      }}
      hoverComponent={({ value }) => {
        const stateData = stateLists[value.name]; 
        return (
          <>
            <p>{value.name}</p>
            <p>{stateData?.content.txt}</p> 
          </>
        );
      }}
      onClick={stateOnClick}
      activeState={activeState}
    />
  );
};

export default Example;
